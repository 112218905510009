<template>
  <b-row class="match-height">
    <b-col>
      <b-card v-if="data.okrSetting != null">
        <profile-information
          :userId="data.okrSetting.employeeId"
        ></profile-information>
        <br />
      </b-card>
    </b-col>
    <b-col cols="12" v-if="!loading">
      <b-tab active title="Left">
        <b-tabs
          class=""
          horizontal
          pills
          nav-wrapper-class="nav-vertical "
          nav-class="justify-content-start"
        >
          <!-- OKR SETTING -->
          <b-tab active :title-item-class="'text-left'">
            <template #title>
              <feather-icon icon="FileIcon" />
              <span>{{ $t("general.okrSetting") }}</span>
            </template>
              <b-card class="mt-1">
                <b-card-text>
                  <div v-if="data.okrSetting == null">
                    <create-okr-setting
                      :okrSetting="data.okrSetting"
                    ></create-okr-setting>
                  </div>
                  <div v-else>
                    <update-okr-setting
                      v-if="
                        data.okrSetting.state != State.APPROVED &&
                        data.okrSetting.state != State.HODAPPROVED &&
                        data.okrSetting.state != State.HRAPPROVED &&
                        data.okrSetting.state != State.GMAPPROVED
                      "
                      :okrSetting="data.okrSetting"
                    ></update-okr-setting>
                    <view-okr-setting
                      :okrSetting="data.okrSetting"
                      v-else
                    ></view-okr-setting>
                  </div>
                </b-card-text>
              </b-card>
          </b-tab>
          <!-- MID YEAR REVIEW -->
          <div v-if="data.okrSetting != null">
            <b-tab
              :disabled="
                data.okrSetting.state != State.HRAPPROVED ? true : false
              "
            >
              <template #title>
                <feather-icon icon="FileMinusIcon" />
                <span>{{ $t("general.midYearReview") }}</span>
              </template>
              <b-card class="mt-1">
                <b-card-text>
                  <create-mid-year-review
                    v-if="data.okrSetting.midYearReview == null"
                    :okrSetting="data.okrSetting"
                  ></create-mid-year-review>
                  <div v-else>
                    <update-mid-year-review
                      v-if="
                        data.okrSetting.midYearReview.state != State.APPROVED &&
                        data.okrSetting.midYearReview.state !=
                          State.HODAPPROVED &&
                        data.okrSetting.midYearReview.state != State.HRAPPROVED &&
                        data.okrSetting.midYearReview.state != State.GMAPPROVED
                      "
                      :okrSetting="data.okrSetting"
                    ></update-mid-year-review>
                    <view-mid-year-review
                      :okrSetting="data.okrSetting"
                      v-else
                    ></view-mid-year-review>
                  </div>
                </b-card-text>
              </b-card>
            </b-tab>
          </div>
          <b-tab v-else disabled>
            <template #title>
              <feather-icon icon="FileMinusIcon" />
              <span>{{ $t("general.midYearReview") }}</span>
            </template>
          </b-tab>
          <!-- YEAR END REVIEW -->
          <div v-if="data.okrSetting != null">
            <div v-if="data.okrSetting.midYearReview != null">
              <b-tab
                :disabled="
                  data.okrSetting.midYearReview.state != State.HRAPPROVED
                    ? true
                    : false
                "
              >
                <template #title>
                  <feather-icon icon="FileTextIcon" />
                  <span>{{ $t("general.yearEndReview") }}</span>
                </template>
                <b-card class="mt-1">
                  <b-card-text v-if="data.okrSetting != null">
                    <create-year-end-review
                      v-if="data.okrSetting.yearEndReview == null"
                      :okrSetting="data.okrSetting"
                    ></create-year-end-review>
                    <div v-else>
                      <update-year-end-review
                        v-if="
                          data.okrSetting.yearEndReview.state !=
                            State.APPROVED &&
                          data.okrSetting.yearEndReview.state !=
                            State.HODAPPROVED &&
                          data.okrSetting.yearEndReview.state !=
                            State.HRAPPROVED &&
                          data.okrSetting.yearEndReview.state != 
                            State.GMAPPROVED &&
                          data.okrSetting.yearEndReview.state !=
                            State.MANAGEMENTAPPROVED
                        "
                        :okrSetting="data.okrSetting"
                      ></update-year-end-review>
                      <view-year-end-review
                        :okrSetting="data.okrSetting"
                        v-else
                      ></view-year-end-review>
                    </div>
                  </b-card-text>
                </b-card>
              </b-tab>
            </div>
            <b-tab v-else disabled>
              <template #title>
                <feather-icon icon="FileTextIcon" />
                <span>{{ $t("general.yearEndReview") }}</span>
              </template>
            </b-tab>
          </div>
          <b-tab v-else disabled>
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>{{ $t("general.yearEndReview") }}</span>
            </template>
          </b-tab>
          <!-- RATING SCALE -->
          <b-tab>
            <template #title>
              <feather-icon icon="AlertCircleIcon" />
              <span>{{ $t("general.ratingScaleValue") }}</span>
            </template>
            <b-card class="mt-1">
              <b-card-text>
                <rating-scale-and-value></rating-scale-and-value>
              </b-card-text>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-col>
  </b-row>
</template>
  
  <script>
import BCardCode from "@core/components/b-card-code";
import { BTabs, BTab, BCardText, BCard, BRow, BCol } from "bootstrap-vue";
import CreateOkrSetting from "./CreateOkrSetting.vue";
import UpdateOkrSetting from "./UpdateOkrSetting.vue";
import ViewOkrSetting from "./ViewOkrSetting.vue";
import { State } from "@/libs/helper";
import CreateMidYearReview from "../midYearReview/CreateMidYearReview.vue";
import UpdateMidYearReview from "../midYearReview/UpdateMidYearReview.vue";
import ViewMidYearReview from "../midYearReview/ViewMidYearReview.vue";
import CreateYearEndReview from "../yearEndReview/CreateYearEndReview.vue";
import UpdateYearEndReview from "../yearEndReview/UpdateYearEndReview.vue";
import ProfileInformation from "@/components/ProfileInformation.vue";
import ViewYearEndReview from "../yearEndReview/ViewYearEndReview.vue";
import RatingScaleAndValue from "../ratingScaleAndValue/RatingScaleAndValue.vue";
import Repository from "@/repositories/RepositoryFactory";
const OkrSettingRepository = Repository.get("okrSetting");
export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BCardText,
    BTabs,
    BTab,
    BCard,
    RatingScaleAndValue,
    CreateOkrSetting,
    UpdateOkrSetting,
    ViewOkrSetting,
    ProfileInformation,
    CreateMidYearReview,
    UpdateMidYearReview,
    ViewMidYearReview,
    CreateYearEndReview,
    UpdateYearEndReview,
    ViewYearEndReview,
  },
  data() {
    return {
      loading: false,
      data: {},
      State,
    };
  },

  created() {
    this.showOkr();
  },
  methods: {
    showOkr() {
      this.loading = true;
      OkrSettingRepository.showOkrSetting(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  setup() {
    const resource = "okrSetting";
    const route = "okr-setting";

    return {
      resource,
      route,
    };
  },
};
</script>
<style>
.zoom-fade-enter-active,
.zoom-fade-leave-active {
  transition: transform 0.35s, opacity 0.28s ease-in-out;
}
.zoom-fade-enter {
  transform: scale(0.97);
  opacity: 0;
}

.zoom-fade-leave-to {
  transform: scale(1.03);
  opacity: 0;
}

</style>
  