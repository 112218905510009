<template>
  <div>
    <div class="ml-2 mr-2">
      <h5>{{ $t("general.stepForOkrReview") }}</h5>
      <b-card-text>
        <p v-html="getTranslate(info, 'okrReview', $i18n.locale)"></p>
      </b-card-text>
    </div>
    <div class="p-2">
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          @submit.prevent="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input-view :fields="fields" v-model="data" :initValue="initData">
            <template #objectives>
              <b-tabs pills class="tab-card mb-2" v-model="tabIndex">
                <b-tab
                  v-for="(type, index) in objectiveTypes"
                  :key="`${type}-${index}`"
                  @click="getCurrentTab(type)"
                >
                  <template #title>
                    <span>
                      {{ getTranslate(type, "title", $i18n.locale) }}
                    </span>
                  </template>
                  <template>
                    <b-card-text
                      class="mb-2 mt-2"
                      v-if="objectiveType.descriptionEn"
                    >
                      <h5>
                        {{
                          getTranslate(
                            objectiveType,
                            "description",
                            $i18n.locale
                          )
                        }}
                      </h5>
                    </b-card-text>
                    <p v-else class="mt-3"></p>
                    <okr-setting-header />
                    <app-collapse accordion type="margin">
                      <app-collapse-item
                        :is-visible="true"
                        :title="`${item}-${index}`"
                        v-for="(item, index) in objectives.filter(
                          (item) => item.objectiveTypeId === objectiveType.id
                        )"
                        :key="`${item}-${index}`"
                        class="mb-1 mt-1"
                        :class="{ 'collapse-item-bg': index % 2 != 0 }"
                      >
                        <template #header>
                          <okr-setting-question
                            v-if="item.objectiveTypeId == 1"
                            :no="index + 1"
                            :weight="item.weight"
                          >
                            <template #error>
                              <feather-icon
                                v-show="item.hasValidate"
                                icon="AlertCircleIcon"
                                class="text-danger"
                              ></feather-icon>
                            </template>
                            <template #question>
                              <div>
                                <b-form-input
                                  :disabled="
                                    initData.state == State.SUBMITTED ||
                                    initData.state == State.APPROVED ||
                                    initData.state == State.HODAPPROVED
                                  "
                                  v-model="item.title"
                                ></b-form-input>
                              </div>
                            </template>
                            <template #weight>
                              <b-input-group append="%">
                                <b-form-input
                                  :disabled="
                                    initData.state == State.SUBMITTED ||
                                    initData.state == State.APPROVED ||
                                    initData.state == State.HODAPPROVED
                                  "
                                  v-model="item.weight"
                                  autocomplete="off"
                                ></b-form-input>
                              </b-input-group>
                            </template>
                          </okr-setting-question>
                          <okr-setting-question
                            v-else
                            :no="index + 1"
                            :weight="item.weight"
                          >
                            <template #error>
                              <feather-icon
                                v-show="item.hasValidate"
                                icon="AlertCircleIcon"
                                class="text-danger"
                              ></feather-icon>
                            </template>
                            <template #question>
                              <div>
                                <h6>
                                  {{
                                    getTranslate(
                                      item.objective,
                                      "description",
                                      $i18n.locale
                                    )
                                  }}
                                </h6>
                                <p>
                                  {{
                                    getTranslate(
                                      item.objective,
                                      "title",
                                      $i18n.locale
                                    )
                                  }}
                                </p>
                              </div>
                            </template>

                            <template #weight>
                              <b-input-group append="%">
                                <b-form-input
                                  :disabled="
                                    initData.state == State.SUBMITTED ||
                                    initData.state == State.APPROVED ||
                                    initData.state == State.HODAPPROVED
                                  "
                                  v-model="item.weight"
                                  autocomplete="off"
                                ></b-form-input>
                              </b-input-group>
                            </template>
                          </okr-setting-question>
                        </template>
                        <hr />
                        <okr-setting-answer>
                          <template #keyresult>
                            <div
                              v-for="(result, idx) in item.objectiveKeyResults"
                              :key="`${result}-${idx}`"
                              :value="result.title"
                            >
                              <b-row>
                                <b-col cols="auto" class="pr-0">
                                  <p class="mt-1">{{ idx + 1 }}.</p>
                                </b-col>
                                <b-col>
                                  <b-form-textarea
                                    :disabled="
                                      initData.state == State.SUBMITTED ||
                                      initData.state == State.APPROVED ||
                                      initData.state == State.HODAPPROVED
                                    "
                                    v-model="result.title"
                                    class="mt-1"
                                    :class="{
                                      'border-danger':
                                        result.hasValidate &&
                                        idx < 3 &&
                                        (result.title == null ||
                                          result.title == ''),
                                    }"
                                    autocomplete="off"
                                  />
                                  <small
                                    v-if="
                                      result.hasValidate &&
                                      idx < 3 &&
                                      (result.title == null ||
                                        result.title == '')
                                    "
                                    class="text-danger"
                                    >{{
                                      $t(`validations.messages.required`, {
                                        _field_: $t("field.keyResult"),
                                      })
                                    }}</small
                                  >
                                </b-col>
                              </b-row>
                            </div>
                          </template>
                        </okr-setting-answer>
                      </app-collapse-item>
                    </app-collapse>
                    <okr-setting-footer
                      :total-weight="calculateWeight(objectiveType.id)"
                    />
                  </template>
                </b-tab>
              </b-tabs>
            </template>
          </n-input-view>

          <div
            class="w-100 text-right"
          >
            <template v-if="
                initData.state != State.SUBMITTED &&
                initData.state != State.APPROVED &&
                initData.state != State.HODAPPROVED
              ">
              <n-button-save-draft
                ref="btnDraft"
                @submit="submit(State.DRAFT)"
                @save="save"
                :loading="loading"
              ></n-button-save-draft>
              <n-button-submit
                v-show="tabIndex == objectiveTypes.length - 1"
                ref="btnSubmit"
                @submit="submit('submit')"
                @save="save"
                :loading="loading"
              ></n-button-submit>
            </template>
            <n-button-loading
              type="button"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :loading="loading"
              class="mt-1"
              @submit="back"
            >
              {{ $t("button.back") }}
            </n-button-loading>
          </div>
        </n-form-confirmation>
      </validation-observer>
    </div>
  </div>
</template>
  
  <script>
import {
  BCol,
  BRow,
  BCard,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BCardText,
  BFormInput,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Repository from "@/repositories/RepositoryFactory";
import { getTranslate, State } from "@/libs/helper";
import OkrSettingQuestion from "@/components/OkrSettingQuestion.vue";
import OkrSettingAnswer from "@/components/OkrSettingAnswer.vue";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import NInputView from "@/components/NInputView";
import NButtonSaveDraft from "@/components/NButtonSaveDraft.vue";
import NButtonSubmit from "@/components/NButtonSubmit.vue";
import OkrSettingHeader from "@/components/OkrSettingHeader.vue";
import OkrSettingFooter from "@/components/OkrSettingFooter.vue";
import FormInput from "./formInput";
import Swal from "sweetalert2";
const OkrSettingRepository = Repository.get("okrSetting");
const ObjectiveTypeRepository = Repository.get("objectiveType");
const StepInformationRepository = Repository.get("stepInformation");
export default {
  components: {
    BFormInput,
    BCard,
    BCol,
    BRow,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
    BButton,
    BFormTextarea,
    BInputGroup,
    BTabs,
    BTab,

    BCardCode,
    AppCollapse,
    AppCollapseItem,
    OkrSettingAnswer,
    OkrSettingQuestion,
    NInput,
    NButtonLoading,
    NFormConfirmation,
    NButtonSaveDraft,
    NButtonSubmit,
    OkrSettingHeader,
    OkrSettingFooter,
    NInputView,
  },
  directives: {
    Ripple,
  },
  props: {
    okrSetting: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      tabIndex: 0,
      info: {},
      data: {
        employeeId: null,
        year: null,
        state: null,
        objectives: [],
      },
      initData: {},
      objectiveType: {},
      objectiveTypes: [],
      objectives: [],
      totalWeight: 0,
      loading: false,
      query: {
        page: Number(this.$route.query.page) || 1,
        order: "sequence_order",
        sort: "ASC",
      },
      user: JSON.parse(localStorage.getItem("userData")),
      getTranslate,
      State,
    };
  },
  created() {
    console.log("Update Okr");
    this.getStepInformation();
    this.getObjectiveType();
    this.show();
  },
  methods: {
    getStepInformation() {
      this.loading = true;
      StepInformationRepository.show()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.info = data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCurrentTab(objectiveType) {
      this.objectiveType = objectiveType;
    },
    getObjectiveType() {
      this.loading = true;
      const params = {
        ...this.query,
      };
      ObjectiveTypeRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.objectiveTypes = [...data.list];

            if (this.objectiveTypes.length > 0) {
              this.objectiveType = this.objectiveTypes[0];
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    calculateWeight(objectiveTypeId) {
      var total = 0;
      this.objectives.forEach((item) => {
        if (item.objectiveTypeId == objectiveTypeId) {
          total += Number(item.weight);
        }
      });

      return total;
    },

    show() {
      this.loading = true;
      const params = `${this.okrSetting.id}`;
      OkrSettingRepository.show(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.initData = {
              ...data,
            };
            this.objectives = this.initData.objectives;
            this.objectives.forEach((objective) => {
              objective.hasValidate = false;
              objective.objectiveKeyResults = objective.objectiveKeyResults.map(
                (keyResult) => {
                  return {
                    ...keyResult,
                    hasValidate: false,
                  };
                }
              );
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },

    checkValidate() {
      this.objectives.forEach((objective) => {
        const countNullValues = objective.objectiveKeyResults.filter(
          (element) => element.title === null || element.title === ""
        ).length;
        if (objective.title == null || objective.title == "") {
          objective.hasValidate = true;
          objective.objectiveKeyResults.forEach((result) => {
            if (
              (result.title == null || result.title == "") &&
              countNullValues > 2
            ) {
              result.hasValidate = true;
            } else {
              result.hasValidate = false;
            }
          });
        } else {
          objective.objectiveKeyResults.forEach((result) => {
            if (
              (result.title == null || result.title == "") &&
              countNullValues > 2
            ) {
              result.hasValidate = true;
              objective.hasValidate = true;
            } else {
              result.hasValidate = false;
              objective.hasValidate = false;
            }
          });
        }
      });
    },

    submit(type) {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          if (type == State.DRAFT) {
            this.$refs.btnDraft.confirm(type);
          } else {
            this.$refs.btnSubmit.confirm(type);
          }
        }
      });
    },

    save(type) {
      this.loading = true;
      if (type == State.DRAFT) {
        this.data.state = State.DRAFT;
      } else {
        if(this.user.canReviewFinal){
          this.data.state = State.HODAPPROVED;
          this.data.hodApproveBy = this.user.id;
        }else{
          this.data.state = State.SUBMITTED;
        }
      }
      (this.data.year = new Date().getFullYear()),
        (this.data.objectives = [...this.objectives]);

      OkrSettingRepository.update(this.okrSetting.id, this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          console.log(error);
          if (error.response?.status == 422) {
            this.checkValidate();
            this.$refs.createForm.setErrors(error.response?.data?.message);
            var message = error.response?.data?.message.objectives;
            Swal.fire({
              title: this.$t("error.invalid"),
              icon: "warning",
              html: message
                .map(
                  (message) =>
                    `<ul class='text-left'><li style='font-size: 14px'>${message}</li></ul>`
                )
                .join(""),
              customClass: {
                confirmButton: "btn btn-primary",
                title: "mb-2",
                content: "p-0",
              },
              buttonsStyling: false,
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `dashboard`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    return { fields };
  },
};
</script>
  