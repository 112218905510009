var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ml-2 mr-2"},[_c('h5',[_vm._v(_vm._s(_vm.$t("general.stepForOkrReview")))]),_c('b-card-text',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.getTranslate(_vm.info, 'okrReview', _vm.$i18n.locale))}})])],1),_c('div',{staticClass:"p-2"},[_c('validation-observer',{ref:"createForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"createForm",attrs:{"form":_vm.$refs.createForm,"disabled":invalid},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('n-input-view',{attrs:{"fields":_vm.fields,"initValue":_vm.initData},scopedSlots:_vm._u([{key:"objectives",fn:function(){return [_c('b-tabs',{staticClass:"tab-card mb-2",attrs:{"pills":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.objectiveTypes),function(type,index){return _c('b-tab',{key:(type + "-" + index),on:{"click":function($event){return _vm.getCurrentTab(type)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.getTranslate(type, "title", _vm.$i18n.locale))+" ")])]},proxy:true}],null,true)},[[(_vm.objectiveType.descriptionEn)?_c('b-card-text',{staticClass:"mb-2 mt-2"},[_c('h5',[_vm._v(" "+_vm._s(_vm.getTranslate( _vm.objectiveType, "description", _vm.$i18n.locale ))+" ")])]):_c('p',{staticClass:"mt-3"}),_c('okr-setting-header'),_c('app-collapse',{attrs:{"accordion":"","type":"margin"}},_vm._l((_vm.objectives.filter(
                        function (item) { return item.objectiveTypeId === _vm.objectiveType.id; }
                      )),function(item,index){return _c('app-collapse-item',{key:(item + "-" + index),staticClass:"mb-1 mt-1",class:{ 'collapse-item-bg': index % 2 != 0 },attrs:{"is-visible":true,"title":(item + "-" + index)},scopedSlots:_vm._u([{key:"header",fn:function(){return [(item.objectiveTypeId == 1)?_c('okr-setting-question',{attrs:{"no":index + 1,"weight":item.weight},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(item.hasValidate),expression:"item.hasValidate"}],staticClass:"text-danger",attrs:{"icon":"AlertCircleIcon"}})]},proxy:true},{key:"question",fn:function(){return [_c('div',[_c('b-form-input',{attrs:{"disabled":_vm.initData.state == _vm.State.SUBMITTED ||
                                  _vm.initData.state == _vm.State.APPROVED ||
                                  _vm.initData.state == _vm.State.HODAPPROVED},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1)]},proxy:true},{key:"weight",fn:function(){return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"disabled":_vm.initData.state == _vm.State.SUBMITTED ||
                                  _vm.initData.state == _vm.State.APPROVED ||
                                  _vm.initData.state == _vm.State.HODAPPROVED,"autocomplete":"off"},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})],1)]},proxy:true}],null,true)}):_c('okr-setting-question',{attrs:{"no":index + 1,"weight":item.weight},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(item.hasValidate),expression:"item.hasValidate"}],staticClass:"text-danger",attrs:{"icon":"AlertCircleIcon"}})]},proxy:true},{key:"question",fn:function(){return [_c('div',[_c('h6',[_vm._v(" "+_vm._s(_vm.getTranslate( item.objective, "description", _vm.$i18n.locale ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.getTranslate( item.objective, "title", _vm.$i18n.locale ))+" ")])])]},proxy:true},{key:"weight",fn:function(){return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"disabled":_vm.initData.state == _vm.State.SUBMITTED ||
                                  _vm.initData.state == _vm.State.APPROVED ||
                                  _vm.initData.state == _vm.State.HODAPPROVED,"autocomplete":"off"},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})],1)]},proxy:true}],null,true)})]},proxy:true}],null,true)},[_c('hr'),_c('okr-setting-answer',{scopedSlots:_vm._u([{key:"keyresult",fn:function(){return _vm._l((item.objectiveKeyResults),function(result,idx){return _c('div',{key:(result + "-" + idx),attrs:{"value":result.title}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('p',{staticClass:"mt-1"},[_vm._v(_vm._s(idx + 1)+".")])]),_c('b-col',[_c('b-form-textarea',{staticClass:"mt-1",class:{
                                    'border-danger':
                                      result.hasValidate &&
                                      idx < 3 &&
                                      (result.title == null ||
                                        result.title == ''),
                                  },attrs:{"disabled":_vm.initData.state == _vm.State.SUBMITTED ||
                                    _vm.initData.state == _vm.State.APPROVED ||
                                    _vm.initData.state == _vm.State.HODAPPROVED,"autocomplete":"off"},model:{value:(result.title),callback:function ($$v) {_vm.$set(result, "title", $$v)},expression:"result.title"}}),(
                                    result.hasValidate &&
                                    idx < 3 &&
                                    (result.title == null ||
                                      result.title == '')
                                  )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("validations.messages.required", { _field_: _vm.$t("field.keyResult"), })))]):_vm._e()],1)],1)],1)})},proxy:true}],null,true)})],1)}),1),_c('okr-setting-footer',{attrs:{"total-weight":_vm.calculateWeight(_vm.objectiveType.id)}})]],2)}),1)]},proxy:true}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),_c('div',{staticClass:"w-100 text-right"},[(
              _vm.initData.state != _vm.State.SUBMITTED &&
              _vm.initData.state != _vm.State.APPROVED &&
              _vm.initData.state != _vm.State.HODAPPROVED
            )?[_c('n-button-save-draft',{ref:"btnDraft",attrs:{"loading":_vm.loading},on:{"submit":function($event){return _vm.submit(_vm.State.DRAFT)},"save":_vm.save}}),_c('n-button-submit',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == _vm.objectiveTypes.length - 1),expression:"tabIndex == objectiveTypes.length - 1"}],ref:"btnSubmit",attrs:{"loading":_vm.loading},on:{"submit":function($event){return _vm.submit('submit')},"save":_vm.save}})]:_vm._e(),_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"button","variant":"outline-secondary","loading":_vm.loading},on:{"submit":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")])],2)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }