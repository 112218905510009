<template>
  <div class="w-100">
    <b-row align-v="center" v-show="$slots.description">
      <b-col cols="1"> </b-col>
      <b-col cols="9">
        <slot name="description"> </slot>
      </b-col>
      <b-col cols="2"> </b-col>
    </b-row>
    <b-row align-v="center">
      <b-col cols="1" class="p-1">
        <h6>{{ no }}.</h6>
      </b-col>
      <b-col cols="9">
        <slot name="title">
          <h6>{{ title }}</h6>
        </slot>
      </b-col>
      <b-col cols="2">
        <h5 class="text-center">{{ weight }}%</h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
  },
  props: {
    no: {
      default: 1,
    },
    title: {
      type: String,
      default: "",
    },
    weight: {
      default: 0,
    },
  },
};
</script>
