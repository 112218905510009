<template>
  <div>
    <div class="ml-2 mr-2">
      <h5>{{ $t("general.stepForOkrMidYearReview") }}</h5>
      <b-card-text>
        <p v-html="getTranslate(info, 'midYearReview', $i18n.locale)"></p>
      </b-card-text>
    </div>

    <div class="p-2">
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input-view :fields="fields" v-model="data" :initValue="initData">
            <template #objectives>
              <b-tabs pills class="tab-card mb-2" v-model="tabIndex">
                <b-tab
                  v-for="(type, index) in objectiveTypes"
                  :key="`${type}-${index}`"
                  @click="getCurrentTab(type)"
                >
                  <template #title>
                    <span>
                      {{ getTranslate(type, "title", $i18n.locale) }}
                    </span>
                  </template>
                  <template>
                    <b-card-text
                      class="mb-2 mt-2"
                      v-if="objectiveType.descriptionEn"
                    >
                      <h5>
                        {{
                          getTranslate(
                            objectiveType,
                            "description",
                            $i18n.locale
                          )
                        }}
                      </h5>
                    </b-card-text>
                    <p v-else class="mt-3"></p>
                    <okr-setting-header />
                    <app-collapse accordion type="margin">
                      <app-collapse-item
                        :is-visible="true"
                        :title="`${item}-${index}`"
                        v-for="(item, index) in objectives.filter(
                          (item) => item.objectiveTypeId === objectiveType.id
                        )"
                        :key="`${item}-${index}`"
                        class="mb-1 mt-1"
                        :class="{ 'collapse-item-bg': index % 2 != 0 }"
                      >
                        <template #header>
                          <okr-setting-question
                            v-if="item.objectiveTypeId == 1"
                            :no="index + 1"
                            :weight="item.weight"
                          >
                            <template #error>
                              <feather-icon
                                v-show="item.hasValidateObjective"
                                icon="AlertCircleIcon"
                                class="text-danger"
                              ></feather-icon>
                            </template>
                            <template #question>
                              <div>
                                <b-form-input
                                  :disabled="initData.state == State.SUBMITTED"
                                  v-model="item.title"
                                ></b-form-input>
                              </div>
                            </template>
                            <template #weight>
                              <b-input-group append="%">
                                <b-form-input
                                  :disabled="initData.state == State.SUBMITTED"
                                  v-model="item.weight"
                                  autocomplete="off"
                                ></b-form-input>
                              </b-input-group>
                            </template>
                          </okr-setting-question>
                          <okr-setting-question
                            v-else
                            :no="index + 1"
                            :weight="item.weight"
                          >
                            <template #error>
                              <feather-icon
                                v-show="item.hasValidateObjective"
                                icon="AlertCircleIcon"
                                class="text-danger"
                              ></feather-icon>
                            </template>
                            <template #question>
                              <div>
                                <h6>
                                  {{
                                    getTranslate(
                                      item.objective,
                                      "description",
                                      $i18n.locale
                                    )
                                  }}
                                </h6>
                                <p>
                                  {{
                                    getTranslate(
                                      item.objective,
                                      "title",
                                      $i18n.locale
                                    )
                                  }}
                                </p>
                              </div>
                            </template>
                            <template #weight>
                              <b-input-group append="%">
                                <b-form-input
                                  :disabled="initData.state == State.SUBMITTED"
                                  v-model="item.weight"
                                  autocomplete="off"
                                ></b-form-input>
                              </b-input-group>
                            </template>
                          </okr-setting-question>
                        </template>
                        <okr-setting-answer>
                          <template #keyresult>
                            <div
                              v-for="(result, idx) in item.objectiveKeyResults"
                              :key="`${result}-${idx}`"
                              :value="result.title"
                            >
                              <hr />
                              <b-row>
                                <b-col>
                                  <label class="text-muted ml-2">{{
                                    $t("field.keyResult")
                                  }}</label>
                                  <b-input-group class="shadow-none">
                                    {{ idx + 1 }}.
                                    <b-form-textarea
                                      :disabled="
                                        initData.state == State.SUBMITTED
                                      "
                                      v-model="result.title"
                                      class="ml-1"
                                      autocomplete="off"
                                      :class="{
                                        'border-danger':
                                          result.hasValidateKeyResult &&
                                          idx < 3 &&
                                          (result.title == null ||
                                            result.title == ''),
                                      }"
                                      rows="2"
                                      max-rows=""
                                    />
                                  </b-input-group>
                                  <small
                                    v-if="
                                      result.hasValidateKeyResult &&
                                      idx < 3 &&
                                      (result.title == null ||
                                        result.title == '')
                                    "
                                    class="ml-2 pt-1 text-danger"
                                  >
                                    {{
                                      $t(`validations.messages.required`, {
                                        _field_: $t("field.keyResult"),
                                      })
                                    }}</small
                                  >
                                </b-col>
                                <b-col>
                                  <label class="text-muted"
                                    >{{ $t("field.employeeReviewMidYear") }}
                                  </label>
                                  <b-form-textarea
                                    :disabled="
                                      initData.state == State.SUBMITTED
                                    "
                                    v-model="result.remark"
                                    autocomplete="off"
                                    :class="{
                                      'border-danger':
                                        result.hasValidateRemark &&
                                        (result.remark == null ||
                                          result.remark == ''),
                                    }"
                                    rows="2"
                                    max-rows="3"
                                  />
                                  <small
                                    v-if="
                                      result.hasValidateRemark &&
                                      (result.remark == null ||
                                        result.remark == '')
                                    "
                                    class="pt-1 text-danger"
                                  >
                                    {{
                                      $t(`validations.messages.required`, {
                                        _field_: $t("field.employeeReview"),
                                      })
                                    }}</small
                                  >
                                </b-col>
                                <b-col cols="2">
                                  <label class="text-muted"
                                    >{{ $t("field.status") }}
                                  </label>
                                  <b-form-group>
                                    <v-select
                                      @input="changeId($event, result.id)"
                                      :label="
                                        $i18n.locale == 'en'
                                          ? 'nameEn'
                                          : 'nameKm'
                                      "
                                      :readonly="true"
                                      :clearable="false"
                                      :value="
                                        $i18n.locale == 'en'
                                          ? result.statusNameEn
                                          : result.statusNameKm
                                      "
                                      :options="result.statuses"
                                      :v-model="result.statusId"
                                      :disabled="
                                        initData.state == State.SUBMITTED
                                      "
                                      :class="{
                                        'border-danger rounded':
                                          result.hasValidateStatus &&
                                          result.statusId == null,
                                      }"
                                    />
                                  </b-form-group>
                                  <small
                                    v-if="
                                      result.hasValidateStatus &&
                                      result.statusId == null
                                    "
                                    class="text-danger"
                                  >
                                    {{
                                      $t(`validations.messages.required`, {
                                        _field_: $t("field.status"),
                                      })
                                    }}</small
                                  >
                                </b-col>
                              </b-row>
                            </div>
                          </template>
                        </okr-setting-answer>
                      </app-collapse-item>
                    </app-collapse>
                    <okr-setting-footer
                      :total-weight="calculateWeight(objectiveType.id)"
                    />
                  </template>
                </b-tab>
              </b-tabs>
            </template>
          </n-input-view>

          <div
            class="w-100 text-right"
            v-show="initData.state != State.SUBMITTED"
          >
            <n-button-save-draft
              ref="btnDraft"
              @submit="submit(State.DRAFT)"
              @save="save"
              :loading="loading"
            ></n-button-save-draft>
            <n-button-submit
              ref="btnSubmit"
              v-show="tabIndex == objectiveTypes.length - 1"
              @submit="submit('submit')"
              @save="save"
              :loading="loading"
            ></n-button-submit>
            <n-button-loading
              type="button"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :loading="loading"
              class="mt-1"
              @submit="back"
            >
              {{ $t("button.back") }}
            </n-button-loading>
          </div>
        </n-form-confirmation>
      </validation-observer>
    </div>
  </div>
</template>
      
      <script>
import {
  BCol,
  BRow,
  BCard,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BCardText,
  BFormInput,
  BButton,
  BFormTextarea,
  BInputGroup,
  BTab,
  BTabs,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Repository from "@/repositories/RepositoryFactory";
import { getTranslate, State } from "@/libs/helper";
import OkrSettingQuestion from "@/components/OkrSettingQuestion.vue";
import OkrSettingAnswer from "@/components/OkrSettingAnswer.vue";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import NInputView from "@/components/NInputView";
import NButtonSaveDraft from "@/components/NButtonSaveDraft.vue";
import NButtonSubmit from "@/components/NButtonSubmit.vue";
import OkrSettingHeader from "@/components/OkrSettingHeader.vue";
import OkrSettingFooter from "@/components/OkrSettingFooter.vue";
import ProfileInformation from "@/components/ProfileInformation.vue";
import FormInput from "./formInput";
import vSelect from "vue-select";
import Swal from "sweetalert2";
const ObjectiveTypeRepository = Repository.get("objectiveType");
const StepInformationRepository = Repository.get("stepInformation");
const MidYearReviewStatusRepository = Repository.get("midYearReviewStatus");
const MidYearReview = Repository.get("midYearReview");
export default {
  components: {
    BFormInput,
    BCard,
    BCol,
    BRow,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
    BButton,
    BFormTextarea,
    BInputGroup,
    BTab,
    BTabs,

    BCardCode,
    AppCollapse,
    AppCollapseItem,
    OkrSettingAnswer,
    OkrSettingQuestion,
    NInput,
    NButtonLoading,
    NFormConfirmation,
    NButtonSaveDraft,
    NButtonSubmit,
    OkrSettingHeader,
    OkrSettingFooter,
    ProfileInformation,
    NInputView,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    okrSetting: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tabIndex: 0,
      info: {},
      data: {
        okrSettingId: null,
        state: null,
        year: null,
        objectives: [],
        comments: [],
      },
      initData: {},
      objectiveType: {},
      objectiveTypes: [],
      objectives: [],
      comments: [],
      statuses: [],
      totalWeight: 0,
      loading: false,
      query: {
        page: Number(this.$route.query.page) || 1,
        order: "sequence_order",
        sort: "ASC",
      },
      user: JSON.parse(localStorage.getItem("userData")),
      getTranslate,
      State,
    };
  },
  created() {
    this.getStepInformation();
    this.getObjectiveType();
    this.getStatus();
    this.show();
  },
  methods: {
    getStepInformation() {
      this.loading = true;
      StepInformationRepository.show()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.info = data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCurrentTab(objectiveType) {
      this.objectiveType = objectiveType;
    },
    getObjectiveType() {
      this.loading = true;
      const params = {
        ...this.query,
      };
      ObjectiveTypeRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.objectiveTypes = [...data.list];

            if (this.objectiveTypes.length > 0) {
              this.objectiveType = this.objectiveTypes[0];
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    calculateWeight(objectiveTypeId) {
      var total = 0;
      this.objectives.forEach((item) => {
        if (item.objectiveTypeId == objectiveTypeId) {
          total += Number(item.weight);
        }
      });

      return total;
    },

    getStatus() {
      MidYearReviewStatusRepository.options().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.statuses = [...data];
        }
      });
    },
    show() {
      this.loading = true;
      const params = `${this.okrSetting.midYearReview.id}`;
      MidYearReview.show(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.initData = {
              ...data,
            };
            this.comments = this.initData.comments;
            this.objectives = this.initData.objectives;
            this.objectives.forEach((objective) => {
              objective.hasValidateObjective = false;
              objective.objectiveKeyResults.forEach((result) => {
                result.hasValidateKeyResult = false;
                result.hasValidateRemark = false;
                result.hasValidateStatus = false;
                result.statuses = this.statuses;
              });
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    changeId(e, keyResultId) {
      this.objectives.forEach((objective) => {
        objective.objectiveKeyResults.forEach((keyResult) => {
          if (keyResultId == keyResult.id) {
            keyResult.statusId = e.id ?? "";
            keyResult.statusNameEn = e.nameEn ?? "";
            keyResult.statusNameKm = e.nameKm ?? "";
          }
        });
      });
    },

    checkErrorValidate() {
      this.objectives.forEach((objective) => {
        const countNullValues = objective.objectiveKeyResults.filter(
          (element) => element.title === null || element.title === ""
        ).length;
        if (objective.title == null || objective.title == "") {
          objective.hasValidateObjective = true;

          objective.objectiveKeyResults.forEach((result) => {
            if (
              (result.title == null || result.title == "") &&
              countNullValues > 2
            ) {
              result.hasValidateKeyResult = true;
            } else {
              result.hasValidateKeyResult = false;
            }
          });
        } else {
          objective.objectiveKeyResults.forEach((result) => {
            if (
              (result.title == null || result.title == "") &&
              countNullValues > 2
            ) {
              result.hasValidateKeyResult = true;
              objective.hasValidateObjective = true;
            } else {
              if (
                result.title != null &&
                (result.remark == null || result.remark == "") &&
                (result.statusId == null || result.statusId == "")
              ) {
                result.hasValidateStatus = true;
                result.hasValidateRemark = true;
                objective.hasValidateObjective = true;
              } else if (
                result.title != null &&
                (result.remark == null || result.remark == "") &&
                (result.statusId != null || result.statusId != "")
              ) {
                result.hasValidateRemark = true;
                result.hasValidateStatus = false;
                objective.hasValidateObjective = true;
              } else if (
                result.title != null &&
                (result.remark != null || result.remark != "") &&
                (result.statusId == null || result.statusId == "")
              ) {
                result.hasValidateStatus = true;
                objective.hasValidateObjective = true;
                result.hasValidateRemark = false;
              } else if (
                result.title != null &&
                (result.remark != null || result.remark != "") &&
                (result.statusId != null || result.statusId != "")
              ) {
                result.hasValidateRemark = false;
                result.hasValidateStatus = false;
                objective.hasValidateObjective = false;
              } else {
                result.hasValidateRemark = false;
                result.hasValidateStatus = false;
              }
            }
          });
        }
      });
    },

    submit(type) {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          if (type == State.DRAFT) {
            this.$refs.btnDraft.confirm(type);
          } else {
            this.$refs.btnSubmit.confirm(type);
          }
        }
      });
    },

    save(type) {
      this.loading = true;
      if (type == State.DRAFT) {
        this.data.state = State.DRAFT;
      } else {
        if (this.user.canReviewFinal) {
          this.data.state = State.HODAPPROVED;
          this.data.hodApproveBy = this.user.id;
        } else {
          this.data.state = State.SUBMITTED;
        }
      }
      this.data.okrSettingId = this.okrSetting.id;
      this.data.year = this.okrSetting.year;
      this.data.comments = [...this.comments];
      this.data.objectives = [...this.objectives];

      this.data.objectives.forEach((obj) => {
        obj.objectiveKeyResults.forEach((key) => {
          if (key.title == null || key.title == "") {
            key.remark = null;
            key.statusId = null;
          }
        });
      });
      MidYearReview.update(this.okrSetting.midYearReview.id, this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.checkErrorValidate();
            this.$refs.createForm.setErrors(error.response?.data?.message);
            var message = error.response?.data?.message.objectives;
            Swal.fire({
              title: this.$t("error.invalid"),
              icon: "warning",
              html: message
                .map(
                  (message) =>
                    `<ul class='text-left'><li style='font-size: 14px'>${message}</li></ul>`
                )
                .join(""),
              customClass: {
                confirmButton: "btn btn-primary",
                title: "mb-2",
                content: "p-0",
              },
              buttonsStyling: false,
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `dashboard`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    return { fields };
  },
};
</script>
  